@import 'theme';

#app-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: rgba(240, 240, 240, 0);
  text-align: center;
  cursor: wait;
}
#app-loading:not(.visible) {
  display: none;
}
.linear-activity {
  overflow: hidden;
  width: 100%;
  height: 4px;
  background-color: rgba($primary, 0.3);
}
.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $primary;
  animation: indeterminate_first 2.5s infinite ease-out;
}

.indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $primary;
  animation: indeterminate_second 2.5s infinite ease-in;
}
.page-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0;
  margin: 0;
  text-align: center;
  perspective: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-loading-overlay div {
  transform: rotateY(0);
  animation: rotateAnimation 2s linear infinite;
}
.page-loading-overlay img {
  display: block;
  height: 40px;
  opacity: 0.3;
}
@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes rotateAnimation {
  from {transform: rotateY(0);}
  to {transform: rotateY(360deg);}
}