@import '../theme';
#sidebar-backdrop {
    background: rgba($dark, 0.7);
    backdrop-filter: blur(5px);
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 21;
}
.sidebar-scrollable {
    overflow-y: auto;
    padding-top: 20px;
}
#sidebar {
    width: 300px;
}
#sidebar .sidebar-inner {
    position: relative;
    z-index: 2;
}
#sidebar .main-logo {
    text-align: center;
    padding: 40px;
    display: block;
}
#sidebar .main-logo img {
    max-width: 220px;
}
#sidebar .beta-badge {
    position: absolute;
    top: 23px;
    right: 20px;
    background: $info;
    font-size: 13px;
    color: #fff;
    padding: 5px 5px;
    line-height: 1;
    border-radius: 3px;
}
#sidebar .main-nav ul {
    list-style: none;
    padding: 0;
}
#sidebar .main-nav ul li {
    display: block;
    padding: 0;
    position: relative;
}

#sidebar .main-nav ul li.demo-disabled:after {
    content: 'DEMO';
    background: $light;
    padding: 3px 5px;
    border-radius: 6px;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    color: $text-muted;
}
#sidebar .main-nav ul li a {
    padding: 10px 40px;
    border-radius: 10px;
    transition: all 0.2s ease;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    color: $dark;
    align-items: center;
}
#sidebar .main-nav ul li.demo-disabled a {
    opacity: 0.4;
}
#sidebar .main-nav ul li a:hover {
    padding-left: 47px;
}
#sidebar .main-nav ul li a span {
    margin-left: 15px;
}
#sidebar .main-nav ul li a.active {
    font-weight: 900;
    background: $primary;
}
#sidebar .main-nav ul li a svg {
    font-size: 22px;
}
#sidebar .sidebar-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    padding: 20px 0;
    background: rgba(255, 255, 255, 0.9);
}
#sidebar .sidebar-bottom h4 {
    font-weight: normal;
    font-size: 20px;
    text-align: center;
    color: #555;
}
#sidebar .switch-inner {
    display: flex;
    justify-content: center;
    font-size: 24px;
    align-items: center;
    gap: 10px;
}
#sidebar .switch-inner .form-switch {
    padding: 0;
}
#sidebar .switch-inner input {
    float: none;
    margin: 0;
    font-size: 34px;
}
.blue-dot-indicator {
    width: 14px;
    height: 14px;
    background: $info;
    border-radius: 100%;
    position: absolute;
    top: -6px;
    right: -6px;
    animation: pulseShadowInfo 2s infinite;
}
.version-switch-modal .modal-dialog {
    overflow: hidden;
}
.version-switch-modal .modal-header {
    background: $info;
    color: #fff;
    text-align: center;
    display: block;
    border: none;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
}

.version-switch-modal .modal-header svg {
    font-size: 32px;
}
.version-switch-modal .modal-header button {
    display: none !important;
}
.version-switch-modal .modal-header span,
.version-switch-modal .modal-header strong {
    display: block;
}
.version-switch-modal .modal-header span {
    font-size: 16px;
    padding-top: 15px;
}
@keyframes pulseShadowInfo {
    0% { box-shadow: 0 0 0 0 rgba($info, 0); }
    20% { box-shadow: 0 0 0 10px rgba($info, 0.2)}
    40% { box-shadow: 0 0 0 5px rgba($info, 0.2)}
    60% { box-shadow: 0 0 0 15px rgba($info, 0)}
}
@media screen and (max-height: 800px) {
    //#sidebar .sidebar-bottom {
    //    box-shadow: 0 -20px 50px #fff;
    //}
    //#sidebar .sidebar-inner {
    //    position: absolute;
    //    bottom: 0;
    //    top: 0;
    //    left: 0;
    //}
    //.sidebar-scrollable {
    //    padding: 20px 20px 300px 20px;
    //    position: absolute;
    //    z-index: 4;
    //    top: 80px;
    //    height: 100%;
    //    left: 0;
    //    right: 0;
    //}
}
@media screen and (max-width: 1180px) {
    .menu-shown #sidebar-backdrop {
        display: block;
    }
    #sidebar {
        display: none;
        z-index: 100;
    }
    .page.menu-shown #sidebar .main-nav ul li {
        padding-left: 0;
    }
    .page.menu-shown #sidebar .main-nav ul li a:not(.active) {
        color: #fff;
    }
    .page.menu-shown #sidebar {
        display: block;
        position: absolute;
    }
    #sidebar .sound-switch h4 {
        display: none;
    }
    .menu-shown .page-container {
        display: block;
    }
}