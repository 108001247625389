@import 'theme';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
* {
  box-sizing: border-box;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

@import 'theme';
@import '~bootstrap/scss/bootstrap';
.nav-tabs .nav-link {
  font-weight: bold;
}
.btn {
  border-radius: 10px;
  font-weight: bold;
}
.input-group .btn {
  border-radius: 5px;
}
.btn:not(.btn-light):not(.btn-link):not(.btn-outline-secondary),
.btn:not(.btn-light):not(.btn-link):hover,
.btn:not(.btn-light):not(.btn-link):active,
.btn:not(.btn-light):not(.btn-link):not(.btn-outline-secondary):focus {
  color: #fff;
}
.btn {
  transition: all 0.1s ease;
  box-shadow: none;
  border: none;
  outline: none;
}
.btn:active:not(:disabled) {
  box-shadow: none;
  border: none;
}
.btn:hover:not(:disabled) {
  box-shadow: none;
}
.btn-primary {
  color: $dark !important;
}
.btn-white,
.btn-white:hover {
  background: #fff;
  color: $dark !important;
}
.btn.btn-link {
  text-decoration: none;
  color: $dark;
}
.btn.btn-link:hover {
  background: rgba(0, 0, 0, 0.05);
}
.page {
  padding-bottom: 60px;
}
h1.title {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h2.title {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h3.title {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
table {
  font-size: 18px;
}
table .badge.status-badge {
  font-size: 18px;
  font-weight: normal;
  padding-left: 20px;
  padding-right: 20px;
}
table .badge.status-badge.bg-danger {
  background-color: rgb(244,217,208) !important;
  color: rgb(196,125,98);
}
/** Responsive */

@include media-breakpoint-down(md) {
  .subscription-boxes {
    flex-direction: column;
  }
  .subscription-boxes .subscription-box {
    max-width: 100%;
    flex: 1 1 100%;
    width: 100%;
  }
}
