$primary: rgb(255,212,55);
$secondary: rgb(3,136,203);
$success: rgb(32, 191, 107);
$info: rgb(45, 152, 218);
$danger: rgb(235, 59, 90);
$warning: rgb(247, 183, 49);
$dark: rgb(24,48,82);
$text-muted: rgba($dark, 0.5);
$light: rgb(240, 240, 240);

$link-color: $secondary;